import React from "react"
import ProductGrid from "../components/product/productGrid"
import Preview from "../components/pages/preview"
import OrderDetails from "../components/pages/orderDetails"
import Header from "../components/header"
import Footer from '../components/footer'
import MetaTag from '../components/metaTag'

const IndexPage = () => {

  return (
    <main className="mainpage">
      <MetaTag title='Подложки ▪ Топперы ▪ Подставки | Wowmilota'/>
      <Header color="white" background="rgba(2,29,37,.75)" buttonColor="hsla(0,0%,100%,.2)" socialColor="rgba(19,15,11,.5)" mainPage={true}/>
      <Preview />
      <ProductGrid />
      <OrderDetails />
      <Footer />
    </main>
  )
}

export default IndexPage
