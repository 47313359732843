import React, { useContext } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import {
  card,
  toppers,
  previewContainer,
  image,
  title,
  footer,
} from "./toppers.module.scss";
import { StoreContext } from "../../../context/store-context";

const preview = "../../../images/products/toppers/main.jpg";

function Toppers() {
  const { store } = useContext(StoreContext);
  const MotionLink = motion(Link);

  return (
    <div className={card}>
      <div className={title}>
        <h2>Топперы</h2>
      </div>
      <MotionLink
        to="/toppers"
        layoutId="toppersContainer"
        className={toppers}
        transition={{ duration: 0 }}
      >
        <div className={previewContainer}>
          <StaticImage
            src={preview}
            alt="Фотография топперов"
            className={image}
          />
        </div>
        {store?.toppers?.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.toppers?.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>
    </div>
  );
}

export default Toppers;
