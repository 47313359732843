import React, { useContext } from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  card,
  cake,
  macarons,
  rubikscube,
  previewContainer,
  image,
  title,
  footer,
} from "./stand.module.scss";
import { StoreContext } from "../../../context/store-context";

const cakePreview = "../../../images/products/stand/cake/main.jpg";
const macaronsPreview = "../../../images/products/stand/macarons/main.jpg";
const rubikcubePreview = "../../../images/products/stand/rubikcube/main.jpg";

function Stand() {
  const { store } = useContext(StoreContext);

  const MotionLink = motion(Link);

  return (
    <div className={card}>
      <MotionLink
        to="/macaronsstand"
        layoutId="macaronsContainer"
        className={macarons}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Макаронс</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={macaronsPreview}
            alt="Фотография подставок для макаронс"
            className={image}
          />
        </div>
        {store?.stand?.macarons.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.stand?.macarons.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>

      <MotionLink
        to="/rubikscubestand"
        layoutId="rubikscubeContainer"
        className={rubikscube}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Кубик Рубика</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={rubikcubePreview}
            alt="Фотография подставок для кубик-рубика"
            layout="fullWidth"
            className={image}
          />
        </div>
        {store?.stand?.rubikcube.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.stand?.rubikcube.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>

      <MotionLink
        to="/cakestand"
        layoutId="cakeContainer"
        className={cake}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Капкейки и торт</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={cakePreview}
            alt="Фотография подставок для капкейков и тортов"
            className={image}
          />
        </div>
        {store?.stand?.cake.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.stand?.cake.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>
    </div>
  );
}

export default Stand;
