import React, { useContext } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import {
  card,
  winter,
  amsterdam,
  previewContainer,
  image,
  title,
  footer,
} from "./screen.module.scss";
import { StoreContext } from "../../../context/store-context";

const winterPreview = "../../../images/products/screens/winter/main.jpg";
const amsterdamPreview = "../../../images/products/screens/amsterdam/main.jpg";

function Screen() {
  const { store } = useContext(StoreContext);
  const MotionLink = motion(Link);

  return (
    <div className={card}>
      <MotionLink
        to="/amsterdamscreens"
        layoutId="amsterdamContainer"
        className={amsterdam}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Амстердам</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={amsterdamPreview}
            alt="Фотография ширмы Амстердам"
            className={image}
          />
        </div>
        {store?.screens?.amsterdam.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.screens?.amsterdam.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>

      <MotionLink
        to="/winterscreens"
        layoutId="winterContainer"
        className={winter}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Лесная сказка</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={winterPreview}
            alt="Фотография ширмы лесная сказка"
            className={image}
          />
        </div>
        {store?.screens?.winter.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.screens?.winter.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>
    </div>
  );
}

export default Screen;
