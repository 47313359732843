import React, { useContext } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import {
  card,
  spatulas,
  previewContainer,
  image,
  title,
  footer,
} from "./spatulas.module.scss";
import { StoreContext } from "../../../context/store-context";

const preview = "../../../images/products/spatulas/main.jpg";

function Spatulas() {
  const { store } = useContext(StoreContext);
  const MotionLink = motion(Link);

  return (
    <div className={card}>
      <div className={title}>
        <h2>Шпатели из акрила</h2>
      </div>
      <MotionLink
        to="/spatulas"
        layoutId="spatulasContainer"
        className={spatulas}
        transition={{ duration: 0 }}
      >
        <div className={previewContainer}>
          <StaticImage
            src={preview}
            alt="Фотография шпателя из акрила"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.spatulas?.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.spatulas?.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>
    </div>
  );
}

export default Spatulas;
