import React, { useContext } from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  card,
  crystal,
  cylinders,
  octahedron,
  previewContainer,
  image,
  title,
  footer,
} from "./acrylicfalsebeads.module.scss";
import { StoreContext } from "../../../context/store-context";

const crystalPreview =
  "../../../images/products/acrylicfalsebeads/crystal/3.JPG";
const cylindersPreview =
  "../../../images/products/acrylicfalsebeads/cylinders/main.JPG";
const octahedronPreview =
  "../../../images/products/acrylicfalsebeads/octahedron/main.JPG";

function Acrylicfalsebeads() {
  const { store } = useContext(StoreContext);

  const MotionLink = motion(Link);

  return (
    <div className={card}>
      <MotionLink
        to="/acryliccylinders"
        layoutId="cylindersContainer"
        className={cylinders}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Цилиндры из акрила</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={cylindersPreview}
            alt="Фотография цилиндра из акрила"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.acrylicfalsebeads?.cylinders.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.acrylicfalsebeads?.cylinders.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>

      <MotionLink
        to="/acrylicoctahedroncubes"
        layoutId="acrylicoctahedroncubesContainer"
        className={octahedron}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Шестигранники и кубы из акрила</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={octahedronPreview}
            alt="Фотография шестигранника или куба из акрила"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.acrylicfalsebeads?.octahedron.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.acrylicfalsebeads?.octahedron.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>

      <MotionLink
        to="/intertiercrystal"
        className={crystal}
        transition={{ duration: 0 }}
        layoutId="crystalContainer"
      >
        <div className={title}>
          <p>Межъярус с хрусталем</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={crystalPreview}
            alt="Фотография межъяруса с хрусталем"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.acrylicfalsebeads?.crystal.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.acrylicfalsebeads?.crystal.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>
    </div>
  );
}

export default Acrylicfalsebeads;
