import { StaticImage } from 'gatsby-plugin-image'
import React from 'react';
import { order, smoke, container, step } from './orderDetails.module.scss'

const smokeImg = '../../images/smoke.png'

function OrderDetails() {
  const details = ['Выберите подходящую категорию', 'Рассчитайте и добавьте необходимые параметры с помощью калькулятора', 'Вы можете добавить или редактировать сразу несколько наборов в одной карточке, если в этом есть необходимость', 'Проверьте корзину и не забудьте заказать дополнительные позиции', 'Если у вас есть вопрос, вы всегда сможете оставить комментарий при отправке заказа', 'Мы сразу обрабатываем заявки и свяжемся с вами в удобном для вас мессенджере для дальнейшего уточнения заказа'];

  return (
    <section className={order}>
      <div className={smoke}>
        <StaticImage src={smokeImg} alt='Дым png' layout='fullWidth' placeholder='none' />
      </div>
      <div className={container}>
        <h2>Как оформить заказ?</h2>
        {details.map((item, index) => {
          return (
            <div className={step} key={`liststep${index}`}>
              <h3>{index} /</h3>
              <p>{item}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default OrderDetails;
