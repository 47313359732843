import React, { useContext } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import {
  card,
  sticks,
  previewContainer,
  image,
  title,
  footer,
} from "./sticks.module.scss";
import { StoreContext } from "../../../context/store-context";

const preview = "../../../images/products/sticks/main.jpg";

function Sticks() {
  const { store } = useContext(StoreContext);

  const MotionLink = motion(Link);

  return (
    <div className={card}>
      <div className={title}>
        <h2>Палочки для эскимо</h2>
      </div>
      <MotionLink
        to="/sticks"
        layoutId="sticksContainer"
        className={sticks}
        transition={{ duration: 0 }}
      >
        <div className={previewContainer}>
          <StaticImage
            src={preview}
            alt="Фотография палочки для эскимо"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.sticks?.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.sticks?.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>
    </div>
  );
}

export default Sticks;
