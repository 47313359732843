import React from "react";
import {
  container,
  containerCategory,
  containerLastCategory,
  headerCategory,
} from "./productGrid.module.scss";
import Spatulas from "./spatulas/spatulas";
import Acrylicfalsebeads from "./acrylicfalsebeads/acrylicfalsebeads";
import Substrates from "./substrates/substrates";
import Toppers from "./toppers/toppers";
import Stand from "./stand/stand";
import Sticks from "./sticks/sticks";
import Tag from "./tag/tag";
import Frame from "./frame/frame";
import Screen from "./screens/screen";
import Shippingbox from "./shippingbox/shippingbox";

function ProductGrid() {
  return (
    <section className={container}>
      <div className={containerCategory}>
        <h2 className={headerCategory}>Подложки</h2>
        <Substrates />
      </div>

      <div className={containerCategory}>
        <h2 className={headerCategory}>Фальшярусы акриловые</h2>
        <Acrylicfalsebeads />
      </div>

      <div className={containerLastCategory}>
        <Toppers />
        <Sticks />
        <Spatulas />
        <Frame />
        <Tag />
      </div>

      <div className={containerCategory}>
        <h2 className={headerCategory}>Короб для перевозки тортов</h2>
        <Shippingbox />
      </div>

      <div className={containerCategory}>
        <h2 className={headerCategory}>Подставки</h2>
        <Stand />
      </div>

      <div className={containerCategory}>
        <h2 className={headerCategory}>Ширмы</h2>
        <Screen />
      </div>
    </section>
  );
}

export default ProductGrid;
