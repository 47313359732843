import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { preview, image, title, large, small } from './preview.module.scss'

const largePreview = '../../images/main.png'
const smallPreview = '../../images/mainmobile.png'


function Preview() {


  return (
    <div className={preview}>
      <div className={image}>
        <StaticImage src={largePreview} alt='Wow Milota подложки топперы подставки' layout='fullWidth' placeholder='none' className={large}/>
        <StaticImage src={smallPreview} alt='Wow Milota подложки топперы подставки' layout='fullWidth' placeholder='none' className={small} />
      </div>
      <div className={title}>
        <h1>Помогаем кондитерам создать торт,<br />который запомнят</h1>
        <h2>Индивидуальные подложки,<br />конструкции, антигравити, топперы</h2>
      </div>
    </div>
  )
}

export default Preview;
