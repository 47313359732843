import React, { useContext } from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  card,
  acrylic,
  plywood,
  lhdf,
  previewContainer,
  image,
  title,
  footer,
} from "./substrates.module.scss";
import { StoreContext } from "../../../context/store-context";

const acrylicPreview = "../../../images/products/substrates/acrylic/main.jpeg";
const plywoodPreview = "../../../images/products/substrates/plywood/main.jpg";
const lhdfPreview = "../../../images/products/substrates/lhdf/main.JPG";

function Substrates() {
  const { store } = useContext(StoreContext);

  const MotionLink = motion(Link);

  return (
    <div className={card}>
      <MotionLink
        to="/arcylicsubstrates"
        className={acrylic}
        transition={{ duration: 0 }}
        layoutId="acrylicContainer"
      >
        <div className={title}>
          <p>Из акрила</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={acrylicPreview}
            alt="Фотография подложек из акрила"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.substrates?.acrylic.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.substrates?.acrylic.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>

      <MotionLink
        to="/plywoodsubsrates"
        layoutId="plywoodContainer"
        className={plywood}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Из фанеры</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={plywoodPreview}
            alt="Фотография подложек из фанеры"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.substrates?.plywood.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.substrates?.plywood.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>

      <MotionLink
        to="/lhdfsubstrates"
        layoutId="lhdfContainer"
        className={lhdf}
        transition={{ duration: 0 }}
      >
        <div className={title}>
          <p>Из ЛХДФ</p>
        </div>
        <div className={previewContainer}>
          <StaticImage
            src={lhdfPreview}
            alt="Фотография подложек из лхдф"
            className={image}
            layout="fullWidth"
          />
        </div>
        {store?.substrates?.lhdf.totalPrice > 0 ? (
          <div
            className={footer}
            style={{
              backgroundColor: "rgba(182, 77, 2, 0.75)",
              color: "white",
            }}
          >
            {store?.substrates?.lhdf.totalPrice} руб.
          </div>
        ) : (
          <div
            className={footer}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            Добавить
          </div>
        )}
      </MotionLink>
    </div>
  );
}

export default Substrates;
