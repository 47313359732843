import React from 'react'

import { container, left, right, center, heycoddes } from './footer.module.scss'

function Footer() {
  return (
    <footer className={container}>
      <div className={left}>
        <p>
          <span>Контакты</span><br />
          Адрес производства: г.Красноярск, пр Красноярский рабочий 27, стр.8, 4 этаж<br />
          Контактный телефон: <a href="tel:+79029111546">+7 (902) 911 15 46</a>
        </p>

      </div>
      <div className={center}>
        <p>
          ИП Мальцева М.П<br />
          ИНН 245406383805<br />
          ОГРНИП 323246800050831<br />
        </p>
      </div>
      <div className={right}>
        <p>
          Наше производство не является шоу-румом, <br />
          приехать к нам можно строго по договоренности
        </p>
        <p className={heycoddes}>Сайт создан командой <a href="https://heycoddes.ru/" target="_blank" rel="noreferrer">Hey!Coddes</a></p>
      </div>
    </footer>
  )
}

export default Footer
